@keyframes mdc-select-float-native-control {
  0% {
    transform: translateY(8px);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

.mdc-line-ripple {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: 2; }

.mdc-line-ripple--active {
  transform: scaleX(1);
  opacity: 1; }

.mdc-line-ripple--deactivating {
  opacity: 0; }

.mdc-notched-outline {
  display: flex;
  position: absolute;
  right: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 100%;
  /* @noflip */
  text-align: left;
  pointer-events: none; }
  [dir="rtl"] .mdc-notched-outline, .mdc-notched-outline[dir="rtl"] {
    /* @noflip */
    text-align: right; }
  .mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
    box-sizing: border-box;
    height: 100%;
    transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
    border-top: 1px solid;
    border-bottom: 1px solid;
    pointer-events: none; }
  .mdc-notched-outline__leading {
    /* @noflip */
    border-left: 1px solid;
    /* @noflip */
    border-right: none;
    width: 12px; }
    [dir="rtl"] .mdc-notched-outline__leading, .mdc-notched-outline__leading[dir="rtl"] {
      /* @noflip */
      border-left: none;
      /* @noflip */
      border-right: 1px solid; }
  .mdc-notched-outline__trailing {
    /* @noflip */
    border-left: none;
    /* @noflip */
    border-right: 1px solid;
    flex-grow: 1; }
    [dir="rtl"] .mdc-notched-outline__trailing, .mdc-notched-outline__trailing[dir="rtl"] {
      /* @noflip */
      border-left: 1px solid;
      /* @noflip */
      border-right: none; }
  .mdc-notched-outline__notch {
    flex: 0 0 auto;
    width: auto;
    max-width: calc(100% - 12px * 2); }
  .mdc-notched-outline .mdc-floating-label {
    display: inline-block;
    position: relative;
    top: 17px;
    bottom: auto;
    max-width: 100%; }
  .mdc-notched-outline .mdc-floating-label--float-above {
    text-overflow: clip; }
  .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    max-width: calc(100% / .75); }

.mdc-notched-outline--notched .mdc-notched-outline__notch {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 8px;
  border-top: none; }
  [dir="rtl"] .mdc-notched-outline--notched .mdc-notched-outline__notch, .mdc-notched-outline--notched .mdc-notched-outline__notch[dir="rtl"] {
    /* @noflip */
    padding-left: 8px;
    /* @noflip */
    padding-right: 0; }

.mdc-notched-outline--no-label .mdc-notched-outline__notch {
  padding: 0; }

.mdc-floating-label {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  position: absolute;
  /* @noflip */
  left: 0;
  /* @noflip */
  transform-origin: left top;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  /* @alternate */
  line-height: 1.15rem;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: text;
  overflow: hidden;
  will-change: transform; }
  [dir="rtl"] .mdc-floating-label, .mdc-floating-label[dir="rtl"] {
    /* @noflip */
    right: 0;
    /* @noflip */
    left: auto;
    /* @noflip */
    transform-origin: right top;
    /* @noflip */
    text-align: right; }

.mdc-floating-label--float-above {
  cursor: auto; }

.mdc-floating-label--float-above {
  transform: translateY(-50%) scale(0.75); }

.mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-standard 250ms 1; }

@keyframes mdc-floating-label-shake-float-above-standard {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 0%)) translateY(-50%) scale(0.75); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 0%)) translateY(-50%) scale(0.75); }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75); } }

@keyframes mdc-ripple-fg-radius-in {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1); }
  to {
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); } }

@keyframes mdc-ripple-fg-opacity-in {
  from {
    animation-timing-function: linear;
    opacity: 0; }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0); } }

@keyframes mdc-ripple-fg-opacity-out {
  from {
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0); }
  to {
    opacity: 0; } }

.mdc-ripple-surface--test-edge-var-bug {
  --mdc-ripple-surface-test-edge-var: 1px solid #000;
  visibility: hidden; }
  .mdc-ripple-surface--test-edge-var-bug::before {
    border: var(--mdc-ripple-surface-test-edge-var); }

.mdc-select--with-leading-icon:not(.mdc-select--disabled) .mdc-select__icon {
  color: #131313;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #131313); }

.mdc-select--with-leading-icon .mdc-select__icon {
  display: inline-block;
  position: absolute;
  bottom: 16px;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  fill: currentColor;
  opacity: 0.54;
  text-decoration: none;
  cursor: pointer;
  user-select: none; }

.mdc-select__icon:not([tabindex]),
.mdc-select__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none; }

.mdc-select-helper-text {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.03333em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  will-change: opacity; }
  .mdc-select-helper-text::before {
    display: inline-block;
    width: 0;
    height: 16px;
    content: "";
    vertical-align: 0; }

.mdc-select-helper-text--persistent {
  transition: none;
  opacity: 1;
  will-change: initial; }

.mdc-select {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  height: 56px;
  overflow: hidden;
  will-change: opacity, transform, color; }
  .mdc-select:not(.mdc-select--disabled) {
    background-color: #f6f6f6; }
  .mdc-select::before, .mdc-select::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-select::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-select.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-select.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-select.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-select.mdc-ripple-upgraded--foreground-activation::after {
    animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards; }
  .mdc-select.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: mdc-ripple-fg-opacity-out 150ms;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-select::before, .mdc-select::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-select.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-select::before, .mdc-select::after {
    background-color: rgba(19, 19, 19, 0.87); }
  .mdc-select:hover::before {
    opacity: 0.04; }
  .mdc-select:not(.mdc-ripple-upgraded):focus::before, .mdc-select.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control,
  .mdc-select:not(.mdc-select--disabled) .mdc-select__selected-text {
    color: rgba(19, 19, 19, 0.87); }
  .mdc-select:not(.mdc-select--disabled) .mdc-floating-label {
    color: rgba(19, 19, 19, 0.6); }
  .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control,
  .mdc-select:not(.mdc-select--disabled) .mdc-select__selected-text {
    border-bottom-color: rgba(19, 19, 19, 0.42); }
  .mdc-select:not(.mdc-select--disabled) + .mdc-select-helper-text {
    color: rgba(19, 19, 19, 0.6); }
  .mdc-select, .mdc-select__native-control {
    border-radius: 4px 4px 0 0; }
  .mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-line-ripple {
    background-color: #0f5b78;
    /* @alternate */
    background-color: var(--mdc-theme-primary, #0f5b78); }
  .mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
    color: rgba(15, 91, 120, 0.87); }
  .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control:hover {
    border-bottom-color: rgba(19, 19, 19, 0.87); }
  .mdc-select .mdc-floating-label--float-above {
    transform: translateY(-70%) scale(0.75); }
  .mdc-select .mdc-floating-label {
    /* @noflip */
    left: 16px;
    /* @noflip */
    right: initial;
    top: 21px;
    pointer-events: none; }
    [dir="rtl"] .mdc-select .mdc-floating-label, .mdc-select .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 16px; }
  .mdc-select.mdc-select--with-leading-icon .mdc-floating-label {
    /* @noflip */
    left: 48px;
    /* @noflip */
    right: initial; }
    [dir="rtl"] .mdc-select.mdc-select--with-leading-icon .mdc-floating-label, .mdc-select.mdc-select--with-leading-icon .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 48px; }
  .mdc-select.mdc-select--outlined .mdc-floating-label {
    /* @noflip */
    left: 4px;
    /* @noflip */
    right: initial;
    top: 17px; }
    [dir="rtl"] .mdc-select.mdc-select--outlined .mdc-floating-label, .mdc-select.mdc-select--outlined .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 4px; }
  .mdc-select.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label {
    /* @noflip */
    left: 36px;
    /* @noflip */
    right: initial; }
    [dir="rtl"] .mdc-select.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label, .mdc-select.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 36px; }
    .mdc-select.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above {
      /* @noflip */
      left: 36px;
      /* @noflip */
      right: initial; }
      [dir="rtl"] .mdc-select.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above, .mdc-select.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above[dir="rtl"] {
        /* @noflip */
        left: initial;
        /* @noflip */
        right: 36px; }
  .mdc-select__dropdown-icon {
    background: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23131313%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.54%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E") no-repeat center;
    /* @noflip */
    left: auto;
    /* @noflip */
    right: 8px;
    position: absolute;
    bottom: 16px;
    width: 24px;
    height: 24px;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
    pointer-events: none; }
    [dir="rtl"] .mdc-select__dropdown-icon, .mdc-select__dropdown-icon[dir="rtl"] {
      /* @noflip */
      left: 8px;
      /* @noflip */
      right: auto; }
    .mdc-select--focused .mdc-select__dropdown-icon {
      background: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%230f5b78%22%20fill-rule%3D%22evenodd%22%20opacity%3D%221%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E") no-repeat center;
      transform: rotate(180deg) translateY(-5px);
      transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1); }
  .mdc-select__native-control {
    padding-top: 20px; }
  .mdc-select.mdc-select--focused .mdc-line-ripple::after {
    transform: scale(1, 2);
    opacity: 1; }

.mdc-select + .mdc-select-helper-text {
  margin-right: 12px;
  margin-left: 12px; }

.mdc-select--outlined + .mdc-select-helper-text {
  margin-right: 16px;
  margin-left: 16px; }

.mdc-select--focused + .mdc-select-helper-text:not(.mdc-select-helper-text--validation-msg) {
  opacity: 1; }

.mdc-select__selected-text {
  min-width: 200px;
  padding-top: 22px; }

.mdc-select__native-control,
.mdc-select__selected-text {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 52px;
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  box-sizing: border-box;
  width: 100%;
  height: 56px;
  padding-top: 20px;
  padding-bottom: 4px;
  border: none;
  border-bottom: 1px solid;
  outline: none;
  background-color: transparent;
  color: inherit;
  white-space: nowrap;
  cursor: pointer;
  appearance: none; }
  [dir="rtl"] .mdc-select__native-control, .mdc-select__native-control[dir="rtl"], [dir="rtl"]
  .mdc-select__selected-text,
  .mdc-select__selected-text[dir="rtl"] {
    /* @noflip */
    padding-left: 52px;
    /* @noflip */
    padding-right: 16px; }
  .mdc-select__native-control::-ms-expand,
  .mdc-select__selected-text::-ms-expand {
    display: none; }
  .mdc-select__native-control::-ms-value,
  .mdc-select__selected-text::-ms-value {
    background-color: transparent;
    color: inherit; }

@-moz-document url-prefix("") {
  .mdc-select__native-control,
  .mdc-select__selected-text {
    text-indent: -2px; } }

.mdc-select--outlined {
  border: none;
  overflow: visible; }
  .mdc-select--outlined:not(.mdc-select--disabled) {
    background-color: transparent; }
  .mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
  .mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
  .mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
    border-color: rgba(19, 19, 19, 0.24); }
  .mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__native-control:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
  .mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__native-control:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
  .mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__native-control:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing,
  .mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__selected-text:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
  .mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__selected-text:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
  .mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__selected-text:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing {
    border-color: rgba(19, 19, 19, 0.87); }
  .mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
  .mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
  .mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
    border-width: 2px; }
  .mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
  .mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
  .mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
    border-color: #0f5b78;
    /* @alternate */
    border-color: var(--mdc-theme-primary, #0f5b78); }
  .mdc-select--outlined .mdc-floating-label--shake {
    animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1; }
  .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    /* @noflip */
    border-radius: 4px 0 0 4px; }
    [dir="rtl"] .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading, .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading[dir="rtl"] {
      /* @noflip */
      border-radius: 0 4px 4px 0; }
  .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
    /* @noflip */
    border-radius: 0 4px 4px 0; }
    [dir="rtl"] .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing[dir="rtl"] {
      /* @noflip */
      border-radius: 4px 0 0 4px; }
  .mdc-select--outlined .mdc-select__native-control {
    border-radius: 4px; }
  .mdc-select--outlined::before, .mdc-select--outlined::after {
    content: none; }
  .mdc-select--outlined:not(.mdc-select--disabled) {
    background-color: transparent; }
  .mdc-select--outlined .mdc-floating-label--float-above {
    transform: translateY(-144%) scale(1); }
  .mdc-select--outlined .mdc-floating-label--float-above {
    font-size: 0.75rem; }
  .mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    transform: translateY(-130%) scale(0.75); }
  .mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    font-size: 1rem; }
  .mdc-select--outlined .mdc-select__native-control,
  .mdc-select--outlined .mdc-select__selected-text {
    /* @noflip */
    padding-left: 16px;
    /* @noflip */
    padding-right: 52px;
    display: flex;
    padding-top: 12px;
    padding-bottom: 12px;
    border: none;
    background-color: transparent;
    z-index: 1; }
    [dir="rtl"] .mdc-select--outlined .mdc-select__native-control, .mdc-select--outlined .mdc-select__native-control[dir="rtl"], [dir="rtl"]
    .mdc-select--outlined .mdc-select__selected-text,
    .mdc-select--outlined .mdc-select__selected-text[dir="rtl"] {
      /* @noflip */
      padding-left: 52px;
      /* @noflip */
      padding-right: 16px; }
  .mdc-select--outlined .mdc-select__selected-text {
    padding-top: 14px; }
  .mdc-select--outlined .mdc-select__icon {
    z-index: 2; }
  .mdc-select--outlined .mdc-floating-label {
    line-height: 1.15rem;
    pointer-events: auto; }

.mdc-select--invalid:not(.mdc-select--disabled) .mdc-floating-label {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020); }

.mdc-select--invalid:not(.mdc-select--disabled) .mdc-select__native-control,
.mdc-select--invalid:not(.mdc-select--disabled) .mdc-select__selected-text {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020); }

.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-line-ripple {
  background-color: #b00020;
  /* @alternate */
  background-color: var(--mdc-theme-error, #b00020); }

.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
  color: #b00020; }

.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--invalid + .mdc-select-helper-text--validation-msg {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020); }

.mdc-select--invalid:not(.mdc-select--disabled) .mdc-select__native-control:hover {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020); }

.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020); }

.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__native-control:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__native-control:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__native-control:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__selected-text:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__selected-text:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__selected-text:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020); }

.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-width: 2px; }

.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020); }

.mdc-select--invalid .mdc-select__dropdown-icon {
  background: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23b00020%22%20fill-rule%3D%22evenodd%22%20opacity%3D%221%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E") no-repeat center; }

.mdc-select--invalid + .mdc-select-helper-text--validation-msg {
  opacity: 1; }

.mdc-select--required .mdc-floating-label::after {
  content: "*"; }

.mdc-select--disabled {
  background-color: #fafafa;
  cursor: default;
  pointer-events: none; }
  .mdc-select--disabled .mdc-floating-label {
    color: rgba(19, 19, 19, 0.37); }
  .mdc-select--disabled .mdc-select__dropdown-icon {
    background: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23131313%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.37%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E") no-repeat center; }
  .mdc-select--disabled .mdc-line-ripple {
    display: none; }
  .mdc-select--disabled .mdc-select__icon {
    color: rgba(19, 19, 19, 0.37); }
  .mdc-select--disabled .mdc-select__native-control,
  .mdc-select--disabled .mdc-select__selected-text {
    color: rgba(19, 19, 19, 0.37);
    border-bottom-style: dotted; }
  .mdc-select--disabled .mdc-select__selected-text {
    pointer-events: none; }
  .mdc-select--disabled.mdc-select--outlined {
    background-color: transparent; }
    .mdc-select--disabled.mdc-select--outlined .mdc-select__native-control,
    .mdc-select--disabled.mdc-select--outlined .mdc-select__selected-text {
      border-bottom-style: none; }
    .mdc-select--disabled.mdc-select--outlined .mdc-notched-outline__leading,
    .mdc-select--disabled.mdc-select--outlined .mdc-notched-outline__notch,
    .mdc-select--disabled.mdc-select--outlined .mdc-notched-outline__trailing {
      border-color: rgba(19, 19, 19, 0.16); }

.mdc-select--with-leading-icon .mdc-select__icon {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-select--with-leading-icon .mdc-select__icon, .mdc-select--with-leading-icon .mdc-select__icon[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 16px; }

.mdc-select--with-leading-icon .mdc-select__native-control,
.mdc-select--with-leading-icon .mdc-select__selected-text {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 32px; }
  [dir="rtl"] .mdc-select--with-leading-icon .mdc-select__native-control, .mdc-select--with-leading-icon .mdc-select__native-control[dir="rtl"], [dir="rtl"]
  .mdc-select--with-leading-icon .mdc-select__selected-text,
  .mdc-select--with-leading-icon .mdc-select__selected-text[dir="rtl"] {
    /* @noflip */
    padding-left: 32px;
    /* @noflip */
    padding-right: 48px; }

.mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--float-above {
  transform: translateY(-144%) translateX(-32px) scale(1); }
  [dir="rtl"] .mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--float-above, .mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--float-above[dir="rtl"] {
    transform: translateY(-144%) translateX(32px) scale(1); }

.mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem; }

.mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--with-leading-icon.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-130%) translateX(-32px) scale(0.75); }
  [dir="rtl"] .mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above, .mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir="rtl"], [dir="rtl"]
  .mdc-select--with-leading-icon.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .mdc-select--with-leading-icon.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir="rtl"] {
    transform: translateY(-130%) translateX(32px) scale(0.75); }

.mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--with-leading-icon.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem; }

.mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon 250ms 1; }

[dir="rtl"] .mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--shake, .mdc-select--with-leading-icon.mdc-select--outlined[dir="rtl"] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon-rtl 250ms 1; }

.mdc-select--with-leading-icon.mdc-select__menu .mdc-list-item__text {
  /* @noflip */
  padding-left: 32px;
  /* @noflip */
  padding-right: 32px; }
  [dir="rtl"] .mdc-select--with-leading-icon.mdc-select__menu .mdc-list-item__text, .mdc-select--with-leading-icon.mdc-select__menu .mdc-list-item__text[dir="rtl"] {
    /* @noflip */
    padding-left: 32px;
    /* @noflip */
    padding-right: 32px; }

.mdc-select__menu .mdc-list .mdc-list-item--selected {
  color: #131313;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #131313); }
  .mdc-select__menu .mdc-list .mdc-list-item--selected::before, .mdc-select__menu .mdc-list .mdc-list-item--selected::after {
    background-color: #131313; }
    @supports not (-ms-ime-align: auto) {
      .mdc-select__menu .mdc-list .mdc-list-item--selected::before, .mdc-select__menu .mdc-list .mdc-list-item--selected::after {
        /* @alternate */
        background-color: var(--mdc-theme-on-surface, #131313); } }
  .mdc-select__menu .mdc-list .mdc-list-item--selected:hover::before {
    opacity: 0.04; }
  .mdc-select__menu .mdc-list .mdc-list-item--selected:not(.mdc-ripple-upgraded):focus::before, .mdc-select__menu .mdc-list .mdc-list-item--selected.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-select__menu .mdc-list .mdc-list-item--selected:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-select__menu .mdc-list .mdc-list-item--selected:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  .mdc-select__menu .mdc-list .mdc-list-item--selected.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }

@keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon {
  0% {
    transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 32px)) translateY(-130%) scale(0.75); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 32px)) translateY(-130%) scale(0.75); }
  100% {
    transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75); } }

@keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon-rtl {
  0% {
    transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - -32px)) translateY(-130%) scale(0.75); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - -32px)) translateY(-130%) scale(0.75); }
  100% {
    transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75); } }

.mdc-menu-surface {
  display: none;
  position: absolute;
  box-sizing: border-box;
  max-width: calc(100vw - 32px);
  max-height: calc(100vh - 32px);
  margin: 0;
  padding: 0;
  transform: scale(1);
  transform-origin: top left;
  opacity: 0;
  overflow: auto;
  will-change: transform, opacity;
  z-index: 8;
  transition: opacity 0.03s linear, transform 0.12s cubic-bezier(0, 0, 0.2, 1);
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  color: #131313;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #131313);
  border-radius: 4px;
  /* @noflip */
  transform-origin-left: top left;
  /* @noflip */
  transform-origin-right: top right; }
  .mdc-menu-surface:focus {
    outline: none; }
  .mdc-menu-surface--open {
    display: inline-block;
    transform: scale(1);
    opacity: 1; }
  .mdc-menu-surface--animating-open {
    display: inline-block;
    transform: scale(0.8);
    opacity: 0; }
  .mdc-menu-surface--animating-closed {
    display: inline-block;
    opacity: 0;
    transition: opacity 0.075s linear; }
  [dir="rtl"] .mdc-menu-surface, .mdc-menu-surface[dir="rtl"] {
    /* @noflip */
    transform-origin-left: top right;
    /* @noflip */
    transform-origin-right: top left; }

.mdc-menu-surface--anchor {
  position: relative;
  overflow: visible; }

.mdc-menu-surface--fixed {
  position: fixed; }

.mdc-icon-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  width: 48px;
  height: 48px;
  padding: 12px;
  font-size: 24px;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: transparent;
  fill: currentColor;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  user-select: none; }
  .mdc-icon-button::before, .mdc-icon-button::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-icon-button::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-icon-button.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-icon-button.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-icon-button.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-icon-button.mdc-ripple-upgraded--foreground-activation::after {
    animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards; }
  .mdc-icon-button.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: mdc-ripple-fg-opacity-out 150ms;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-icon-button::before, .mdc-icon-button::after {
    top: calc(50% - 50%);
    /* @noflip */
    left: calc(50% - 50%);
    width: 100%;
    height: 100%; }
  .mdc-icon-button.mdc-ripple-upgraded::before, .mdc-icon-button.mdc-ripple-upgraded::after {
    top: var(--mdc-ripple-top, calc(50% - 50%));
    /* @noflip */
    left: var(--mdc-ripple-left, calc(50% - 50%));
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-icon-button.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-icon-button svg,
  .mdc-icon-button img {
    width: 24px;
    height: 24px; }
  .mdc-icon-button:disabled {
    color: rgba(0, 0, 0, 0.38);
    /* @alternate */
    color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
    cursor: default;
    pointer-events: none; }
  .mdc-icon-button::before, .mdc-icon-button::after {
    background-color: #131313; }
  .mdc-icon-button:hover::before {
    opacity: 0.04; }
  .mdc-icon-button:not(.mdc-ripple-upgraded):focus::before, .mdc-icon-button.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-icon-button:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-icon-button:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  .mdc-icon-button.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }

.mdc-icon-button__icon {
  display: inline-block; }
  .mdc-icon-button__icon.mdc-icon-button__icon--on {
    display: none; }

.mdc-icon-button--on .mdc-icon-button__icon {
  display: none; }
  .mdc-icon-button--on .mdc-icon-button__icon.mdc-icon-button__icon--on {
    display: inline-block; }

.mdc-text-field-helper-text {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.03333em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  will-change: opacity; }
  .mdc-text-field-helper-text::before {
    display: inline-block;
    width: 0;
    height: 16px;
    content: "";
    vertical-align: 0; }

.mdc-text-field-helper-text--persistent {
  transition: none;
  opacity: 1;
  will-change: initial; }

.mdc-text-field-character-counter {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.03333em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 0; }
  .mdc-text-field-character-counter::before {
    display: inline-block;
    width: 0;
    height: 16px;
    content: "";
    vertical-align: 0; }
  [dir="rtl"] .mdc-text-field-character-counter, .mdc-text-field-character-counter[dir="rtl"] {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: auto; }
  [dir="rtl"] .mdc-text-field-character-counter, .mdc-text-field-character-counter[dir="rtl"] {
    /* @noflip */
    padding-left: 0;
    /* @noflip */
    padding-right: 16px; }

.mdc-text-field--with-leading-icon .mdc-text-field__icon,
.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  position: absolute;
  bottom: 16px;
  cursor: pointer; }

.mdc-text-field__icon:not([tabindex]),
.mdc-text-field__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none; }

.mdc-text-field {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  border-radius: 4px 4px 0 0;
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  height: 56px;
  overflow: hidden;
  will-change: opacity, transform, color; }
  .mdc-text-field::before, .mdc-text-field::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-text-field::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  .mdc-text-field.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-text-field.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-text-field.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-text-field.mdc-ripple-upgraded--foreground-activation::after {
    animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards; }
  .mdc-text-field.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: mdc-ripple-fg-opacity-out 150ms;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-text-field::before, .mdc-text-field::after {
    background-color: rgba(19, 19, 19, 0.87); }
  .mdc-text-field:hover::before {
    opacity: 0.04; }
  .mdc-text-field:not(.mdc-ripple-upgraded):focus::before, .mdc-text-field.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-text-field::before, .mdc-text-field::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-text-field.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: rgba(19, 19, 19, 0.6); }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: rgba(19, 19, 19, 0.87); }
  .mdc-text-field .mdc-text-field__input {
    caret-color: #0f5b78;
    /* @alternate */
    caret-color: var(--mdc-theme-primary, #0f5b78); }
  .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
    border-bottom-color: rgba(19, 19, 19, 0.42); }
  .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input:hover {
    border-bottom-color: rgba(19, 19, 19, 0.87); }
  .mdc-text-field .mdc-line-ripple {
    background-color: #0f5b78;
    /* @alternate */
    background-color: var(--mdc-theme-primary, #0f5b78); }
  .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--textarea) {
    border-bottom-color: rgba(19, 19, 19, 0.12); }
  .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: rgba(19, 19, 19, 0.6); }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
  .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: rgba(19, 19, 19, 0.6); }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon {
    color: rgba(19, 19, 19, 0.54); }
  .mdc-text-field:not(.mdc-text-field--disabled) {
    background-color: #f6f6f6; }
  .mdc-text-field .mdc-floating-label {
    /* @noflip */
    left: 16px;
    /* @noflip */
    right: initial;
    top: 18px;
    pointer-events: none; }
    [dir="rtl"] .mdc-text-field .mdc-floating-label, .mdc-text-field .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 16px; }
  .mdc-text-field--textarea .mdc-floating-label {
    /* @noflip */
    left: 4px;
    /* @noflip */
    right: initial; }
    [dir="rtl"] .mdc-text-field--textarea .mdc-floating-label, .mdc-text-field--textarea .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 4px; }
  .mdc-text-field--outlined .mdc-floating-label {
    /* @noflip */
    left: 4px;
    /* @noflip */
    right: initial;
    top: 17px; }
    [dir="rtl"] .mdc-text-field--outlined .mdc-floating-label, .mdc-text-field--outlined .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 4px; }
  .mdc-text-field--outlined--with-leading-icon .mdc-floating-label {
    /* @noflip */
    left: 36px;
    /* @noflip */
    right: initial; }
    [dir="rtl"] .mdc-text-field--outlined--with-leading-icon .mdc-floating-label, .mdc-text-field--outlined--with-leading-icon .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 36px; }
    .mdc-text-field--outlined--with-leading-icon .mdc-floating-label--float-above {
      /* @noflip */
      left: 40px;
      /* @noflip */
      right: initial; }
      [dir="rtl"] .mdc-text-field--outlined--with-leading-icon .mdc-floating-label--float-above, .mdc-text-field--outlined--with-leading-icon .mdc-floating-label--float-above[dir="rtl"] {
        /* @noflip */
        left: initial;
        /* @noflip */
        right: 40px; }

.mdc-text-field__input {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  align-self: flex-end;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 20px 16px 6px;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  background: none;
  appearance: none; }
  .mdc-text-field__input::placeholder {
    transition: opacity 67ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0;
    color: rgba(19, 19, 19, 0.54); }
  .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(19, 19, 19, 0.54) !important; }
  .mdc-text-field--fullwidth .mdc-text-field__input::placeholder,
  .mdc-text-field--no-label .mdc-text-field__input::placeholder,
  .mdc-text-field--focused .mdc-text-field__input::placeholder {
    transition-delay: 40ms;
    transition-duration: 110ms;
    opacity: 1; }
  .mdc-text-field__input:focus {
    outline: none; }
  .mdc-text-field__input:invalid {
    box-shadow: none; }
  .mdc-text-field__input:-webkit-autofill {
    z-index: auto !important; }
  .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
    padding: 16px; }

.mdc-text-field__input:-webkit-autofill + .mdc-floating-label {
  transform: translateY(-50%) scale(0.75);
  cursor: auto; }

.mdc-text-field--outlined {
  border: none;
  overflow: visible; }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: rgba(19, 19, 19, 0.24); }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing {
    border-color: rgba(19, 19, 19, 0.87); }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
    border-color: #0f5b78;
    /* @alternate */
    border-color: var(--mdc-theme-primary, #0f5b78); }
  .mdc-text-field--outlined .mdc-floating-label--shake {
    animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1; }
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    /* @noflip */
    border-radius: 4px 0 0 4px; }
    [dir="rtl"] .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading, .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading[dir="rtl"] {
      /* @noflip */
      border-radius: 0 4px 4px 0; }
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
    /* @noflip */
    border-radius: 0 4px 4px 0; }
    [dir="rtl"] .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing[dir="rtl"] {
      /* @noflip */
      border-radius: 4px 0 0 4px; }
  .mdc-text-field--outlined .mdc-floating-label--float-above {
    transform: translateY(-144%) scale(1); }
  .mdc-text-field--outlined .mdc-floating-label--float-above {
    font-size: 0.75rem; }
  .mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    transform: translateY(-130%) scale(0.75); }
  .mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    font-size: 1rem; }
  .mdc-text-field--outlined::before, .mdc-text-field--outlined::after {
    content: none; }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) {
    background-color: transparent; }
  .mdc-text-field--outlined .mdc-text-field__input {
    display: flex;
    padding: 12px 16px 14px;
    border: none !important;
    background-color: transparent;
    z-index: 1; }
  .mdc-text-field--outlined .mdc-text-field__icon {
    z-index: 2; }

.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__trailing {
  border-width: 2px; }

.mdc-text-field--outlined.mdc-text-field--disabled {
  background-color: transparent; }
  .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__leading,
  .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__notch,
  .mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: rgba(19, 19, 19, 0.06); }
  .mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input {
    border-bottom: none; }

.mdc-text-field--outlined.mdc-text-field--dense {
  height: 48px; }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above {
    transform: translateY(-134%) scale(1); }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above {
    font-size: 0.8rem; }
  .mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    transform: translateY(-120%) scale(0.8); }
  .mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    font-size: 1rem; }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake {
    animation: mdc-floating-label-shake-float-above-text-field-outlined-dense 250ms 1; }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__input {
    padding: 12px 12px 7px; }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label {
    top: 14px; }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__icon {
    top: 12px; }

.mdc-text-field--with-leading-icon .mdc-text-field__icon {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon .mdc-text-field__icon, .mdc-text-field--with-leading-icon .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 16px; }

.mdc-text-field--with-leading-icon .mdc-text-field__input {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 16px; }
  [dir="rtl"] .mdc-text-field--with-leading-icon .mdc-text-field__input, .mdc-text-field--with-leading-icon .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 16px;
    /* @noflip */
    padding-right: 48px; }

.mdc-text-field--with-leading-icon .mdc-floating-label {
  /* @noflip */
  left: 48px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon .mdc-floating-label, .mdc-text-field--with-leading-icon .mdc-floating-label[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 48px; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__icon {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 16px; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__input {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 16px; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__input, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 16px;
    /* @noflip */
    padding-right: 48px; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-144%) translateX(-32px) scale(1); }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above[dir="rtl"] {
    transform: translateY(-144%) translateX(32px) scale(1); }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-130%) translateX(-32px) scale(0.75); }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir="rtl"], [dir="rtl"]
  .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir="rtl"] {
    transform: translateY(-130%) translateX(32px) scale(0.75); }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon 250ms 1; }

[dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir="rtl"] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl 250ms 1; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label {
  /* @noflip */
  left: 36px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 36px; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above {
  transform: translateY(-134%) translateX(-21px) scale(1); }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above[dir="rtl"] {
    transform: translateY(-134%) translateX(21px) scale(1); }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above {
  font-size: 0.8rem; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-120%) translateX(-21px) scale(0.8); }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir="rtl"], [dir="rtl"]
  .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir="rtl"] {
    transform: translateY(-120%) translateX(21px) scale(0.8); }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense 250ms 1; }

[dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense[dir="rtl"] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense-rtl 250ms 1; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label {
  /* @noflip */
  left: 32px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 32px; }

.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 12px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon .mdc-text-field__icon, .mdc-text-field--with-trailing-icon .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: 12px;
    /* @noflip */
    right: initial; }

.mdc-text-field--with-trailing-icon .mdc-text-field__input {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 48px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon .mdc-text-field__input, .mdc-text-field--with-trailing-icon .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 48px;
    /* @noflip */
    padding-right: 12px; }

.mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__icon {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__icon, .mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: 16px;
    /* @noflip */
    right: initial; }

.mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__input {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 48px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__input, .mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 48px;
    /* @noflip */
    padding-right: 16px; }

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: auto; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: auto;
    /* @noflip */
    right: 16px; }
  .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon ~ .mdc-text-field__icon {
    /* @noflip */
    right: 12px;
    /* @noflip */
    left: auto; }
    [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon ~ .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon ~ .mdc-text-field__icon[dir="rtl"] {
      /* @noflip */
      right: auto;
      /* @noflip */
      left: 12px; }

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__input {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 48px; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__input, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 48px;
    /* @noflip */
    padding-right: 48px; }

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon,
.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon {
  bottom: 16px;
  transform: scale(0.8); }

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon {
  /* @noflip */
  left: 12px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 12px; }

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input {
  /* @noflip */
  padding-left: 44px;
  /* @noflip */
  padding-right: 12px; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 12px;
    /* @noflip */
    padding-right: 44px; }

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label {
  /* @noflip */
  left: 44px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 44px; }

.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 12px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon, .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: 12px;
    /* @noflip */
    right: initial; }

.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 44px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input, .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 44px;
    /* @noflip */
    padding-right: 12px; }

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon {
  /* @noflip */
  left: 12px;
  /* @noflip */
  right: auto; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: auto;
    /* @noflip */
    right: 12px; }
  .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon ~ .mdc-text-field__icon {
    /* @noflip */
    right: 12px;
    /* @noflip */
    left: auto; }
    [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon ~ .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon ~ .mdc-text-field__icon[dir="rtl"] {
      /* @noflip */
      right: auto;
      /* @noflip */
      left: 12px; }

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input {
  /* @noflip */
  padding-left: 44px;
  /* @noflip */
  padding-right: 44px; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 44px;
    /* @noflip */
    padding-right: 44px; }

.mdc-text-field--dense .mdc-floating-label--float-above {
  transform: translateY(-70%) scale(0.8); }

.mdc-text-field--dense .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-dense 250ms 1; }

.mdc-text-field--dense .mdc-text-field__input {
  padding: 12px 12px 0; }

.mdc-text-field--dense .mdc-floating-label {
  font-size: .813rem; }
  .mdc-text-field--dense .mdc-floating-label--float-above {
    font-size: .813rem; }

.mdc-text-field__input:required ~ .mdc-floating-label::after,
.mdc-text-field__input:required ~ .mdc-notched-outline .mdc-floating-label::after {
  margin-left: 1px;
  content: "*"; }

.mdc-text-field--textarea {
  display: inline-flex;
  width: auto;
  height: auto;
  transition: none;
  overflow: visible; }
  .mdc-text-field--textarea:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--textarea:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--textarea:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: rgba(19, 19, 19, 0.24); }
  .mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
  .mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
  .mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing,
  .mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
  .mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
  .mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing {
    border-color: rgba(19, 19, 19, 0.87); }
  .mdc-text-field--textarea:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
  .mdc-text-field--textarea:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
  .mdc-text-field--textarea:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
    border-color: #0f5b78;
    /* @alternate */
    border-color: var(--mdc-theme-primary, #0f5b78); }
  .mdc-text-field--textarea .mdc-floating-label--shake {
    animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1; }
  .mdc-text-field--textarea .mdc-notched-outline .mdc-notched-outline__leading {
    /* @noflip */
    border-radius: 4px 0 0 4px; }
    [dir="rtl"] .mdc-text-field--textarea .mdc-notched-outline .mdc-notched-outline__leading, .mdc-text-field--textarea .mdc-notched-outline .mdc-notched-outline__leading[dir="rtl"] {
      /* @noflip */
      border-radius: 0 4px 4px 0; }
  .mdc-text-field--textarea .mdc-notched-outline .mdc-notched-outline__trailing {
    /* @noflip */
    border-radius: 0 4px 4px 0; }
    [dir="rtl"] .mdc-text-field--textarea .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-text-field--textarea .mdc-notched-outline .mdc-notched-outline__trailing[dir="rtl"] {
      /* @noflip */
      border-radius: 4px 0 0 4px; }
  .mdc-text-field--textarea::before, .mdc-text-field--textarea::after {
    content: none; }
  .mdc-text-field--textarea:not(.mdc-text-field--disabled) {
    background-color: transparent; }
  .mdc-text-field--textarea .mdc-floating-label--float-above {
    transform: translateY(-144%) scale(1); }
  .mdc-text-field--textarea .mdc-floating-label--float-above {
    font-size: 0.75rem; }
  .mdc-text-field--textarea.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .mdc-text-field--textarea .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    transform: translateY(-130%) scale(0.75); }
  .mdc-text-field--textarea.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
  .mdc-text-field--textarea .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    font-size: 1rem; }
  .mdc-text-field--textarea .mdc-text-field-character-counter {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 16px;
    position: absolute;
    bottom: 13px; }
    [dir="rtl"] .mdc-text-field--textarea .mdc-text-field-character-counter, .mdc-text-field--textarea .mdc-text-field-character-counter[dir="rtl"] {
      /* @noflip */
      left: 16px;
      /* @noflip */
      right: initial; }
  .mdc-text-field--textarea .mdc-text-field__input {
    align-self: auto;
    box-sizing: border-box;
    height: auto;
    margin: 8px 1px 1px 0;
    padding: 0 16px 16px;
    border: none; }
  .mdc-text-field--textarea .mdc-text-field-character-counter + .mdc-text-field__input {
    margin-bottom: 28px;
    padding-bottom: 0; }
  .mdc-text-field--textarea .mdc-floating-label {
    top: 17px;
    bottom: auto;
    width: auto;
    pointer-events: none; }
  .mdc-text-field--textarea.mdc-text-field--focused .mdc-notched-outline__leading,
  .mdc-text-field--textarea.mdc-text-field--focused .mdc-notched-outline__notch,
  .mdc-text-field--textarea.mdc-text-field--focused .mdc-notched-outline__trailing {
    border-width: 2px; }

.mdc-text-field--fullwidth {
  width: 100%; }
  .mdc-text-field--fullwidth:not(.mdc-text-field--textarea) {
    display: block; }
    .mdc-text-field--fullwidth:not(.mdc-text-field--textarea)::before, .mdc-text-field--fullwidth:not(.mdc-text-field--textarea)::after {
      content: none; }
    .mdc-text-field--fullwidth:not(.mdc-text-field--textarea):not(.mdc-text-field--disabled) {
      background-color: transparent; }
    .mdc-text-field--fullwidth:not(.mdc-text-field--textarea) .mdc-text-field__input {
      padding: 0; }
  .mdc-text-field--fullwidth.mdc-text-field--textarea .mdc-text-field__input {
    resize: vertical; }

.mdc-text-field--fullwidth.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--textarea) {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020); }

.mdc-text-field-helper-line {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box; }
  .mdc-text-field--dense + .mdc-text-field-helper-line {
    margin-bottom: 4px; }
  .mdc-text-field + .mdc-text-field-helper-line {
    padding-right: 16px;
    padding-left: 16px; }

.mdc-form-field > .mdc-text-field + label {
  align-self: flex-start; }

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(15, 91, 120, 0.87); }

.mdc-text-field--focused .mdc-text-field__input:required ~ .mdc-floating-label::after,
.mdc-text-field--focused .mdc-text-field__input:required ~ .mdc-notched-outline .mdc-floating-label::after {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--focused + .mdc-text-field-helper-line .mdc-text-field-helper-text:not(.mdc-text-field-helper-text--validation-msg) {
  opacity: 1; }

.mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #0f5b78;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #0f5b78); }

.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input:hover {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple {
  background-color: #b00020;
  /* @alternate */
  background-color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: #b00020;
  /* @alternate */
  caret-color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--invalid.mdc-text-field--with-trailing-icon:not(.mdc-text-field--with-leading-icon):not(.mdc-text-field--disabled) .mdc-text-field__icon {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--invalid.mdc-text-field--with-trailing-icon.mdc-text-field--with-leading-icon:not(.mdc-text-field--disabled) .mdc-text-field__icon ~ .mdc-text-field__icon {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  opacity: 1; }

.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020); }

.mdc-text-field--disabled {
  background-color: #fafafa;
  border-bottom: none;
  pointer-events: none; }
  .mdc-text-field--disabled .mdc-text-field__input {
    border-bottom-color: rgba(19, 19, 19, 0.06); }
  .mdc-text-field--disabled .mdc-text-field__input {
    color: rgba(19, 19, 19, 0.37); }
  .mdc-text-field--disabled .mdc-floating-label {
    color: rgba(19, 19, 19, 0.37); }
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: rgba(19, 19, 19, 0.37); }
  .mdc-text-field--disabled .mdc-text-field-character-counter,
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: rgba(19, 19, 19, 0.37); }
  .mdc-text-field--disabled .mdc-text-field__icon {
    color: rgba(19, 19, 19, 0.3); }
  .mdc-text-field--disabled:not(.mdc-text-field--textarea) {
    border-bottom-color: rgba(19, 19, 19, 0.12); }
  .mdc-text-field--disabled .mdc-floating-label {
    cursor: default; }

.mdc-text-field--textarea.mdc-text-field--disabled {
  background-color: transparent;
  background-color: #f9f9f9; }
  .mdc-text-field--textarea.mdc-text-field--disabled .mdc-notched-outline__leading,
  .mdc-text-field--textarea.mdc-text-field--disabled .mdc-notched-outline__notch,
  .mdc-text-field--textarea.mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: rgba(19, 19, 19, 0.06); }
  .mdc-text-field--textarea.mdc-text-field--disabled .mdc-text-field__input {
    border-bottom: none; }

@keyframes mdc-floating-label-shake-float-above-text-field-dense {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.8); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 0%)) translateY(-70%) scale(0.8); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 0%)) translateY(-70%) scale(0.8); }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.8); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 0%)) translateY(-130%) scale(0.75); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 0%)) translateY(-130%) scale(0.75); }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-dense {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-120%) scale(0.8); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 0%)) translateY(-120%) scale(0.8); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 0%)) translateY(-120%) scale(0.8); }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-120%) scale(0.8); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon {
  0% {
    transform: translateX(calc(0 - 0)) translateY(-130%) scale(0.75); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 0)) translateY(-130%) scale(0.75); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 0)) translateY(-130%) scale(0.75); }
  100% {
    transform: translateX(calc(0 - 0)) translateY(-130%) scale(0.75); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense {
  0% {
    transform: translateX(calc(0 - 21px)) translateY(-120%) scale(0.8); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 21px)) translateY(-120%) scale(0.8); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 21px)) translateY(-120%) scale(0.8); }
  100% {
    transform: translateX(calc(0 - 21px)) translateY(-120%) scale(0.8); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl {
  0% {
    transform: translateX(calc(0 - 0)) translateY(-130%) scale(0.75); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 0)) translateY(-130%) scale(0.75); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 0)) translateY(-130%) scale(0.75); }
  100% {
    transform: translateX(calc(0 - 0)) translateY(-130%) scale(0.75); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense-rtl {
  0% {
    transform: translateX(calc(0 - -21px)) translateY(-120%) scale(0.8); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - -21px)) translateY(-120%) scale(0.8); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - -21px)) translateY(-120%) scale(0.8); }
  100% {
    transform: translateX(calc(0 - -21px)) translateY(-120%) scale(0.8); } }

@keyframes mdc-floating-label-shake-float-above-textarea {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 0%)) translateY(-130%) scale(0.75); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 0%)) translateY(-130%) scale(0.75); }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75); } }

.mdc-menu {
  min-width: 112px; }
  .mdc-menu .mdc-list-item__meta {
    color: rgba(0, 0, 0, 0.87);
    /* @alternate */
    color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87)); }
  .mdc-menu .mdc-list-item__graphic {
    color: rgba(0, 0, 0, 0.87);
    /* @alternate */
    color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87)); }
  .mdc-menu .mdc-list-divider {
    margin: 8px 0; }
  .mdc-menu .mdc-list-item {
    cursor: pointer;
    user-select: none; }
  .mdc-menu .mdc-list-item--disabled {
    cursor: auto; }
  .mdc-menu a.mdc-list-item .mdc-list-item__text,
  .mdc-menu a.mdc-list-item .mdc-list-item__graphic {
    pointer-events: none; }

.mdc-menu__selection-group {
  padding: 0;
  fill: currentColor; }
  .mdc-menu__selection-group .mdc-list-item {
    /* @noflip */
    padding-left: 56px;
    /* @noflip */
    padding-right: 0; }
    [dir="rtl"] .mdc-menu__selection-group .mdc-list-item, .mdc-menu__selection-group .mdc-list-item[dir="rtl"] {
      /* @noflip */
      padding-left: 0;
      /* @noflip */
      padding-right: 56px; }
  .mdc-menu__selection-group .mdc-menu__selection-group-icon {
    /* @noflip */
    left: 16px;
    /* @noflip */
    right: initial;
    display: none;
    position: absolute; }
    [dir="rtl"] .mdc-menu__selection-group .mdc-menu__selection-group-icon, .mdc-menu__selection-group .mdc-menu__selection-group-icon[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 16px; }

.mdc-menu-item--selected .mdc-menu__selection-group-icon {
  display: inline; }

.mdc-list {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  /* @alternate */
  line-height: 1.5rem;
  margin: 0;
  padding: 8px 0;
  list-style-type: none;
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87)); }

.mdc-list-item__secondary-text {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54)); }

.mdc-list-item__graphic {
  background-color: transparent; }

.mdc-list-item__graphic {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38)); }

.mdc-list-item__meta {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38)); }

.mdc-list-group__subheader {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87)); }

.mdc-list--dense {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: .812rem; }

.mdc-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  height: 48px;
  padding: 0 16px;
  overflow: hidden; }
  .mdc-list-item:focus {
    outline: none; }

.mdc-list-item--selected,
.mdc-list-item--activated {
  color: #0f5b78;
  /* @alternate */
  color: var(--mdc-theme-primary, #0f5b78); }
  .mdc-list-item--selected .mdc-list-item__graphic,
  .mdc-list-item--activated .mdc-list-item__graphic {
    color: #0f5b78;
    /* @alternate */
    color: var(--mdc-theme-primary, #0f5b78); }

.mdc-list-item--disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38)); }

.mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 32px;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  fill: currentColor; }
  .mdc-list-item[dir="rtl"] .mdc-list-item__graphic,
  [dir="rtl"] .mdc-list-item .mdc-list-item__graphic {
    /* @noflip */
    margin-left: 32px;
    /* @noflip */
    margin-right: 0; }

.mdc-list .mdc-list-item__graphic {
  display: inline-flex; }

.mdc-list-item__meta {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0; }
  .mdc-list-item[dir="rtl"] .mdc-list-item__meta,
  [dir="rtl"] .mdc-list-item .mdc-list-item__meta {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: auto; }

.mdc-list-item__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.mdc-list-item__text[for] {
  pointer-events: none; }

.mdc-list-item__primary-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
  display: block; }
  .mdc-list-item__primary-text::before {
    display: inline-block;
    width: 0;
    height: 32px;
    content: "";
    vertical-align: 0; }
  .mdc-list-item__primary-text::after {
    display: inline-block;
    width: 0;
    height: 20px;
    content: "";
    vertical-align: -20px; }
  .mdc-list--dense .mdc-list-item__primary-text {
    display: block;
    margin-top: 0;
    /* @alternate */
    line-height: normal;
    margin-bottom: -20px; }
    .mdc-list--dense .mdc-list-item__primary-text::before {
      display: inline-block;
      width: 0;
      height: 24px;
      content: "";
      vertical-align: 0; }
    .mdc-list--dense .mdc-list-item__primary-text::after {
      display: inline-block;
      width: 0;
      height: 20px;
      content: "";
      vertical-align: -20px; }

.mdc-list-item__secondary-text {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.01786em;
  text-decoration: inherit;
  text-transform: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  display: block; }
  .mdc-list-item__secondary-text::before {
    display: inline-block;
    width: 0;
    height: 20px;
    content: "";
    vertical-align: 0; }
  .mdc-list--dense .mdc-list-item__secondary-text {
    display: block;
    margin-top: 0;
    /* @alternate */
    line-height: normal;
    font-size: inherit; }
    .mdc-list--dense .mdc-list-item__secondary-text::before {
      display: inline-block;
      width: 0;
      height: 20px;
      content: "";
      vertical-align: 0; }

.mdc-list--dense .mdc-list-item {
  height: 40px; }

.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 36px;
  width: 20px;
  height: 20px; }
  .mdc-list-item[dir="rtl"] .mdc-list--dense .mdc-list-item__graphic,
  [dir="rtl"] .mdc-list-item .mdc-list--dense .mdc-list-item__graphic {
    /* @noflip */
    margin-left: 36px;
    /* @noflip */
    margin-right: 0; }

.mdc-list--avatar-list .mdc-list-item {
  height: 56px; }

.mdc-list--avatar-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%; }
  .mdc-list-item[dir="rtl"] .mdc-list--avatar-list .mdc-list-item__graphic,
  [dir="rtl"] .mdc-list-item .mdc-list--avatar-list .mdc-list-item__graphic {
    /* @noflip */
    margin-left: 16px;
    /* @noflip */
    margin-right: 0; }

.mdc-list--two-line .mdc-list-item__text {
  align-self: flex-start; }

.mdc-list--two-line .mdc-list-item {
  height: 72px; }

.mdc-list--two-line.mdc-list--dense .mdc-list-item,
.mdc-list--avatar-list.mdc-list--dense .mdc-list-item {
  height: 60px; }

.mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 20px;
  width: 36px;
  height: 36px; }
  .mdc-list-item[dir="rtl"] .mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic,
  [dir="rtl"] .mdc-list-item .mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic {
    /* @noflip */
    margin-left: 20px;
    /* @noflip */
    margin-right: 0; }

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before {
    transition: opacity 15ms linear, background-color 15ms linear;
    z-index: 1; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-activation::after {
    animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: mdc-ripple-fg-opacity-out 150ms;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::after {
    background-color: #131313; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:hover::before {
    opacity: 0.04; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::before {
    opacity: 0.12; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::after {
    background-color: #0f5b78; }
    @supports not (-ms-ime-align: auto) {
      :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::after {
        /* @alternate */
        background-color: var(--mdc-theme-primary, #0f5b78); } }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:hover::before {
    opacity: 0.16; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.24; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.28; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.28; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::before {
    opacity: 0.08; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::after {
    background-color: #0f5b78; }
    @supports not (-ms-ime-align: auto) {
      :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::after {
        /* @alternate */
        background-color: var(--mdc-theme-primary, #0f5b78); } }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:hover::before {
    opacity: 0.12; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.2; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.24; }
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.24; }

a.mdc-list-item {
  color: inherit;
  text-decoration: none; }

.mdc-list-divider {
  height: 0;
  margin: 0;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid; }

.mdc-list-divider {
  border-bottom-color: rgba(0, 0, 0, 0.12); }

.mdc-list-divider--padded {
  margin: 0 16px; }

.mdc-list-divider--inset {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px); }
  .mdc-list-group[dir="rtl"] .mdc-list-divider--inset,
  [dir="rtl"] .mdc-list-group .mdc-list-divider--inset {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 72px; }

.mdc-list-divider--inset.mdc-list-divider--padded {
  width: calc(100% - 72px - 16px); }

.mdc-list-group .mdc-list {
  padding: 0; }

.mdc-list-group__subheader {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  margin: 0.75rem 16px; }

.mdc-text-field--fullwidth {
  width: 100%; }

.mdc-select {
  width: 100%;
  margin: 8px 0;
  background-color: #fff !important; }
  .mdc-select .mdc-select--fullwidth {
    width: 100%; }
  .mdc-select .mdc-select--checkbox .mdc-floating-label {
    transform: translateY(-130%) scale(0.75) !important; }
  .mdc-select .mdc-select--checkbox .mdc-select__menu.mdc-menu.mdc-menu-surface {
    display: inline-block !important;
    transform: scale(1) !important;
    opacity: 1 !important; }

.mdc-select--float.mdc-select--outlined .mdc-floating-label {
  top: 21px !important;
  left: 16px !important;
  transform: translateY(-150%) translateX(-13%) scale(0.75) !important;
  background: #fff !important;
  z-index: 1;
  color: #131313 !important;
  font-weight: 600; }

.mdc-select--outlined .mdc-select__native-control,
.mdc-select--outlined .mdc-select__selected-text {
  display: initial; }

.mdc-list .mdc-list-item .mdc-list-item--no_text.mdc-list-item__graphic.material-icons {
  margin-right: 0; }

.mdc-list .mdc-list-item .mdc-list-item__graphic.material-icons {
  margin-right: 10px; }

.mdc-list .mdc-list-group .mdc-list-item {
  padding-left: 24px; }

.mdc-card {
  border-radius: 8px;
  margin-bottom: 10px; }
  .mdc-card .mdc-card--body {
    height: 100%; }
  .mdc-card .mdc-card--title {
    background: var(--mdc-theme-primary, #0f5b78);
    color: var(--mdc-theme-on-primary, #dcdcdc);
    border-radius: 8px 8px 0 0;
    padding: 10px 16px;
    font-size: 1rem;
    font-weight: 800; }
    .mdc-card .mdc-card--title .mdc-icon-button {
      height: 28px;
      width: 28px;
      padding: 0px; }
    .mdc-card .mdc-card--title .title-action {
      display: block;
      float: right; }
    .mdc-card .mdc-card--title > span {
      margin-right: 80px; }
  .mdc-card .company-logo {
    display: block;
    height: 25px;
    margin: 10px auto 15px; }
  .mdc-card .company-full-logo {
    display: block;
    max-width: 90%;
    margin: 10px auto 15px;
    max-height: 10rem; }
  .mdc-card img.company-round-logo {
    height: 17rem;
    display: block;
    margin: 10px auto -4rem; }
  .mdc-card .title {
    font-size: 24px;
    text-align: center;
    margin: 5px 0 10px; }
  .mdc-card .subtitle {
    text-align: center; }
  .mdc-card .mdc-card--content, .mdc-card.mdc-card--content {
    padding: 12px;
    position: relative; }

.mdc-floating-label.mdc-floating-label--float-above {
  color: #131313 !important;
  font-weight: 600;
  z-index: 1; }

@media (max-width: 479px) {
  .hide-on-small {
    display: none; } }

.truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.hide {
  display: none !important; }

.text-center {
  text-align: center; }

.absolute-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1; }

.valign-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center; }

.type-icon {
  font-size: .9rem;
  margin-left: 0;
  margin-right: .5rem; }
  .type-icon.building-icon {
    color: #00f; }
  .type-icon.content-icon {
    color: green; }
  .type-icon.restoration-icon {
    color: #f9a825; }

.remove-padding-bot {
  padding-bottom: 0px !important; }

.mdc-select__dropdown-clear {
  z-index: 10;
  left: auto;
  right: 24px;
  position: absolute;
  bottom: 16px;
  width: 24px;
  height: 24px; }
