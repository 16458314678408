.pagination {
  justify-content: center;
  list-style: none;
  display: flex;
  padding: 0;
}

ul.pagination li {
  display: inline-block;
  width: 30px;
  border: 1px solid #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

ul.pagination li.previous {
  border: none;
  font-size: 24px;
}
ul.pagination li.next {
  border: none;
  font-size: 24px;
}

ul.pagination li a {
  outline: none;
  text-decoration: none;
  color: black;
  font-size: 12px;
}

ul.pagination li.active a {
  color: white;
}
ul.pagination li.active {
  background-color: lightgrey;
}

ul.pagination li a:hover,
ul.pagination li a.active {
  background-color: lightgrey;
  cursor: pointer;
}

.page-selection {
  width: 48px;
  height: 30px;
  color: #337ab7;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
